.App {
  text-align: center;
  padding-top: 50px;
}

.App-header {
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 60px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: left;
  font-size: calc(15px + 2vmin);
  color: #282c34a4;
}

.App-header-bold {
  color: #282c34;
}
